<template>
  <div class="d-flex">
    <div class="d-block w-100 align-self-center mr-1">
      <b-form-input
        v-if="edit_state"
        v-model="FacultyArea.name"
        :state="FacultyArea.name.trim().length > 0"
      ></b-form-input>
      <span v-else>
        {{ FacultyArea.name }}
      </span>
    </div>
    <template v-if="!edit_state">
      <button-edit
        class="btn-div pb-1 m-0"
        v-b-tooltip.v-secondary.noninteractive="`Editar`"
        @click="() => (edit_state = !edit_state)"
      ></button-edit>
    </template>
    <template v-else>
      <button
        class="btn-div btn-class pb-0 m-0"
        @click="updateFacultyArea()"
        v-b-tooltip.v-secondary.noninteractive="`Guardar`"
      >
        <b-icon font-scale="1" icon="check2-square"></b-icon>
      </button>
    </template>
    <button-delete
      v-b-tooltip.v-secondary.noninteractive="`Eliminar`"
      class="btn-div pb-1 ml-1 m-0"
      @click="deleteFacultyArea()"
    ></button-delete>
  </div>
</template>

<script>
// import * as names from "@/store/names";
// import { mapGetters } from "vuex";
// import { generateUniqueId } from "@/utils/utils";
export default {
  name: "TagFacultyArea",
  components: {},
  props: {
    Faculty: { type: Object },
    FacultyArea: {
      type: Object,
    },
  },
  data() {
    return {
      edit_state: false,
    };
  },
  computed: {
    // ...mapGetters({}),
  },
  methods: {
    updateFacultyArea() {
      if (this.FacultyArea.name.trim().length > 0) {
        this.edit_state = !this.edit_state;
        if (!isNaN(this.Faculty.id)) {
          this.$restful
            .Patch(`/mesh/faculty-area/${this.FacultyArea.id}/`, {
              name: this.FacultyArea.name,
            })
            .then((response) => {
              this.$emit("updated", response);
            });
        }
      }
    },
    deleteFacultyArea() {
      this.$emit("deleted", this.FacultyArea);
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.btn-div {
  color: white;
}
.btn-class {
  border: none;
  background: transparent;
  font-size: 15pt;
  padding: 0;
}
</style>